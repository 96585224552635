import React, { useState } from 'react'
import './index.css'
import { map as rmap, split, startsWith, endsWith } from 'ramda'
import GoBack from '../../_generics/GoBack'
import Video from '../../_generics/Video'
import Sound from '../../_generics/Sound'
import ButtonWithSound from '../../_generics/ButtonWithSound'
import HomeBtn from '../../_generics/HomeBtn'
import Ticker from 'react-ticker'
import GoogleMap from '../../_generics/GoogleMap';
import LanguagesBtn from '../../_generics/LanguagesBtn';
import Picture from '../../_generics/Picture';
import { FaMapMarkerAlt } from "react-icons/fa"
import { IconContext } from "react-icons"
import Dropdown from 'react-dropdown'
import { AiOutlineSearch } from "react-icons/ai"
import { getTranslation } from '../../../services/translations';

const makeLinks = rmap(
  link => (
    <div key={link.title} className="link-container">
      <img style={{ width: '20%', height: '100%' }} src={'https://image.cnbcfm.com/api/v1/image/106278717-1575487551656screenshot2019-12-04at2.25.16pm.png?v=1575487585&w=678&h=381'} />
      <a className="link-title hover-opacity" href={link.address}>
        {link.title}
      </a>
      <div className="link-sound-container">
        <Sound src={link.sound} />
      </div>
    </div>
  )
)

const makeButtons = (activePath, setActivePath) => rmap(
  button => (
    <ButtonWithSound
      key={button.title}
      button={button}
      onClick={() => {
        if (!button.linkTo) {
          return
        }
        if (startsWith('/', button.linkTo)) {
          setActivePath(/*activePath + '/' + */
            button.linkTo
          )
        } else {
          if (startsWith('http://', button.linkTo) || startsWith('https://', button.linkTo)) {
            // window.location.href = button.linkTo
            window.open(button.linkTo, '_blank')
          } else if (startsWith('tel:', button.linkTo)) {
            window.location.href = button.linkTo
          } else {
            //window.location.href = 'http://' + button.linkTo
            window.open('http://' + button.linkTo, '_blank')
          }
        }
      }} />
  )
)

export default ({ activePath, setActivePath, video, links, picture, text, map, buttons, infoScroll, coordinatesStored, showLanguages, setShowLanguages, selectedHost, setSelectedHost, hosts, structure, language, setShowSearch }) => {
  const sidescrollTexts = infoScroll && infoScroll[0] && infoScroll[0].text && split(',', infoScroll[0].text)
  const filteredHosts = window.location.hostname.includes('co.tz') ?
    hosts.filter(_ => (_.username === 'Dar-es-Salaam') || (_.username === 'Dodoma')) :
    hosts.filter(_ => !(_.username === 'Dar-es-Salaam' || _.username === 'Dodoma'))
  return (
    <div className="Menu">
      { endsWith('/Kategoriat', activePath) &&
      <div className="top-left city-selection">
        <div>
          <IconContext.Provider value={{ className: "gps-icon" }}>
            <div>
              <FaMapMarkerAlt />
            </div>
          </IconContext.Provider>
        </div>
        <Dropdown
          controlClassName="city-dropdown"
          options={
            rmap(
              _ => ({
                label: _.username,
                value: _
              }),
              filteredHosts
            )
          }
          onChange={(_) => setSelectedHost(_.value)}
          value={selectedHost.username} />
        <div style={{ marginBottom: -13, marginRight: -5, color: 'rgba(0,0,0,0.6)' }}>
          <Sound src={selectedHost.sound} />
        </div>
      </div> }

      <div className="fade-in">

        { endsWith('/Kategoriat', activePath) && 
        <div className="search-input-front-page-container">
          <div onClick={() => setShowSearch(true)} className="search-input-front-page">
            <IconContext.Provider value={{ className: "search-btn-icon" }}>
              <div>
                <AiOutlineSearch />
              </div>
            </IconContext.Provider>
            <div className="search-input-text">{ getTranslation(language, 'Hae sivulta') }</div>
          </div>
        </div> }

        { text && text[0] && text[0].content &&
        <div className="page-text">
          <pre
            className="small-text"
            style={(language === 'العربيّة' || language === 'دری') ? { textAlign: 'right' } : {}}>
            { text[0].content }
          </pre>
          <div
            className={ !endsWith('/Kategoriat', activePath) ?
            "page-text-sound" :
            "page-text-sound animateIn"
          }>
            <Sound src={text[0].sound}/>
          </div>
        </div> }

        <div className="btns-container">
          { makeButtons(activePath, setActivePath)(buttons) }
        </div>

        <Video video={video} />

        <Picture picture={picture} />

        { infoScroll && infoScroll[0] && infoScroll[0].text &&
        <div style={{ paddingTop: 40, paddingBottom: 30, position: 'relative', marginRight: 40 }}>
          <Sound
            src={infoScroll && infoScroll[0] && infoScroll[0].sound}
            containerStyle={{
              backgroundColor: 'rgba(249,247,240)',
              position: 'absolute',
              top: 35,
              right: -40,
              zIndex: 100000,
              color: 'rgba(0,0,0,0.6)'
            }} />
          <Ticker>
            {({ index }) => 
              <div className="ticker-item-1">
                { sidescrollTexts[index % sidescrollTexts.length] }
              </div>
            }
          </Ticker>
        </div> }

        <GoogleMap map={map} coordinatesStored={coordinatesStored} />

        <div style={{ height: 130 }} />

      </div>

    { !endsWith('/Kategoriat', activePath) &&
    <GoBack
      activePath={activePath}
      setActivePath={setActivePath} /> }
    { /* !endsWith('/Kategoriat', activePath) &&
    <HomeBtn
      activePath={activePath}
      setActivePath={setActivePath} /> */ }
    <LanguagesBtn
     showLanguages={showLanguages}
     activePath={activePath}
     structure={structure}
     selectedHost={selectedHost}
     setShowLanguages={setShowLanguages}
     language={language} />

    { !endsWith('/Kategoriat', activePath) &&
    <div onClick={ () => setShowSearch(true) } className="search-btn">
      <IconContext.Provider value={{ className: "search-btn-icon" }}>
        <div>
          <AiOutlineSearch />
        </div>
      </IconContext.Provider>
    </div> }
  </div>
  )
}
