import axios from 'axios'
import { last, split, assoc, dissoc, compose, replace, map, mapObjIndexed } from 'ramda'
import { mapKeys } from '../utils';
import offlineSite from '../site.js'

const DEFAULT_REQUESTER = 'tienoo'
const BASE_URL = 'https://tienoo.' + (window.location.hostname.includes('co.tz') ? 'co.tz' : 'fi') // 'https://upcoming.tienoo.fi'

var AWS = require("aws-sdk")
var s3_sites_access = new AWS.S3({
  region: 'eu-west-1',
  accessKeyId: 'AKIA4TWP6HG3V66NFZ74',
  secretAccessKey: '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'
})

// if publishedSite
// /getPublishedSite
// site.json and published_site.json

const SITE_URL = BASE_URL + '/getSite'
const HOSTS_URL = BASE_URL + '/getHosts'

export const getSite = requester => axios({
  method: 'get',
  url: SITE_URL,
  headers: {
    requester: requester === 'Suomi' ? DEFAULT_REQUESTER : DEFAULT_REQUESTER + '/' + requester /* + '/' + requester */ // DIsabled for now, for demo
  }
}).then(
  res => {
    const data = res.data
   /*  const parsedData = compose(
      dissoc('CITY'),
      assoc(
        last(split('/', requester)),
        data['CITY']
      )
    )(data) */
    const parsedData = mapKeys(
      replace(
        /CITY/g,
        last(split('/', requester)),
      )
    )(data)
    return parsedData
  }
).catch(
  err => ({})
)


/**
 * 
 * 
 * 
 * getBaseSite_v2().then(baseSite => {
        console.log('asd', baseSite)
        setSite(baseSite)
        if (baseSite && baseSite['/CITY']) {
          setLanguages(keys(baseSite['/CITY']))
        }
        setFetched(true) 
      })


      export const getBaseSite_v2 = () => new Promise( (resolve, reject) => s3_sites_access.getObject(
  {
    Bucket: 'admin.tienoo.fi', 
    Key: getUsername() + '/site.json',
  },
  function(err, data) {
    if (err) {
      console.log(err)
      resolve('Error saving. Try removing special characters (€,∞,#..)')
    } else {
      console.log(data)
      const dataString = data.Body.toString()
      const response = dataString ? JSON.parse(dataString) : 'Couldt get site.'
      resolve(response)
    }
  }
)
)


var AWS = require("aws-sdk")
var s3_sites_access = new AWS.S3({
  region: 'eu-west-1',
  accessKeyId: 'AKIAUBPFWE3OGQ4H7PVD',
  secretAccessKey: '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'
})
 */


export const getSite_v2 = (requester) => new Promise( (resolve, reject) => s3_sites_access.getObject(
  {
    Bucket: 'tienoo', 
    Key: requester === 'Suomi' ?
      DEFAULT_REQUESTER + '/published-site.json' : // '/published-site.json': // site.json with upcoming... , published-site.json with tienoo.fi
      DEFAULT_REQUESTER + '/' + requester + '/published-site.json',
  },
  function(err, data) {
    if (err) {
      console.log(err)
      resolve(
        mapKeys(
          replace(
            /CITY/g,
            last(split('/', requester)),
          )
        )(offlineSite)
      )
      resolve('Error saving. Try removing special characters (€,∞,#..)')
    } else {
      console.log(data)
      const dataString = data.Body.toString()

      if (!dataString) {
        return 'No data.'
      }

      const responseObject = JSON.parse(dataString)

      const parsedData = mapKeys(
        replace(
          /CITY/g,
          last(split('/', requester)),
        )
      )(responseObject)


      resolve(parsedData)
    }
  }
)
)

export const getHosts = params => axios({
  method: 'get',
  url: HOSTS_URL,
  headers: {
    requester: DEFAULT_REQUESTER
  }
}).then(
  res => {
    const data = res.data
    const parsedData = map(
      obj => {
        obj.longitude = Number(obj.longitude)
        obj.latitude = Number(obj.latitude)
        obj.username = last(split('/', obj.username))
        return obj
      }
    )(data)
    return parsedData
  }
).catch(
  err => [
    {
      username: 'Suomi',
      latitude: 25,
      longitude: 60
    }
  ] 
)