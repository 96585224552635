export const getTranslation = (language, text) => {
  if (text === 'Hae tästä') {
    return {
      'Suomi': 'Hae tästä',
      'العربيّة': 'ابحث هنا',
      'English': 'Search here',
      'دری': 'جستجو',
      'Kiswahili': 'Tafuta hapa',
      'Soomaali': 'Ka dalbo halkan',
      'Français': 'Chercher ici',
      'Español': 'Buscar aquí',
      'Svenska': 'Sök här'
    }[language]
  }
  if (text === 'Hae sivulta') {
    return {
      'Suomi': 'Hae sivulta',
      'العربيّة': 'ابحث في الصفحة',
      'English': 'Search the site',
      'دری': 'در سایت جستجو کنید',
      'Kiswahili': 'Tafuta katika tovuti',
      'Soomaali': 'Ka raadi bogga',
      'Français': 'Chercher sur la page',
      'Español': 'Buscar en la página',
      'Svenska': 'Sök från sidan'
    }[language]
  }
  if (text === 'Valitse kieli') {
    return {
      'Suomi': 'Valitse kieli',
      'العربيّة': 'Choose your language',
      'English': 'Choose your language',
      'دری': 'Choose your language',
      'Kiswahili': 'Choose your language',
      'Soomaali': 'Choose your language',
      'Français': 'Choose your language',
      'Español': 'Choose your language',
      'Svenska': 'Choose your language'
    }[language]
  }
  return text
}