import React, { useEffect, useState } from 'react'
import { split, startsWith, map as rmap } from 'ramda'
import Sound from '../Sound'
import { getCurrentCoordinates } from '../../../services/location';

const getMapUrl = (item, coordinates) => {
  if (!startsWith('<iframe src="https://www.google.com/maps', item)) {
    const zoomLevel = split('_zoom=', item)[1] || '13'
    const searchTerm = split('_zoom=', item)[0]
    return coordinates.latitude ? 
      'https://www.google.com/maps/embed/v1/search?key=AIzaSyB6fZV9d7cOPGp5Oav8d6DEZHvNGcPIyvQ&q=' + searchTerm + '&zoom=' + zoomLevel + '&center=' + coordinates.latitude + ',' + coordinates.longitude :
      'https://www.google.com/maps/embed/v1/search?key=AIzaSyB6fZV9d7cOPGp5Oav8d6DEZHvNGcPIyvQ&q=' + searchTerm
  }
  return split('"', item)[1]      
}

export default ({ map, coordinatesStored }) => {
  //const embed = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126608.26587206974!2d24.68332042183978!3d60.27579240368462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469207c7a548b679%3A0xdbc4c98cd610058b!2sKela%20Tikkurila!5e0!3m2!1sen!2sfi!4v1583960006839!5m2!1sen!2sfi" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>'
  const [ coords, setCoords ] = useState(null)

  useEffect(() => {
    getCurrentCoordinates(true).then((_) => {
      setCoords(_)
    })
  }, [])

  if (!map || !coords) {
    return <div />
  }
  return rmap(
    item => {
      if (!item.embed || item.hidden) return <div key={'none'} />
      //if (!startsWith('<iframe src="https://www.google.com/maps', item.embed)) return <div key={'nourl'} />
      //const googleMapslink = split('"', item.embed)[1]      
      return (
        <div key={item.embed}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
            <div className="small-text" style={{
              padding: 15,
              paddingBottom: 0,
              textAlign: window.location.href && (window.location.href.split('lang=')[1] === '%D8%A7%D9%84%D8%B9%D8%B1%D8%A8%D9%8A%D9%91%D8%A9' || window.location.href.split('lang=')[1] === '%D8%AF%D8%B1%DB%8C' || window.location.href.split('lang=')[1] === 'دری') ? 'right' : 'left'
              }}>{ item.title }</div>
            <div style={{ padding: 15, paddingBottom: 0 }}>
              <Sound src={item.sound} />
            </div>
          </div>
          <iframe
            src={getMapUrl(item.embed, coords)}
            frameBorder="0"
            style={{ border: 0, width: '100%', height: '75vw', maxHeight: 450 }}
            allowFullScreen={true}
            aria-hidden="false"
            tabIndex="0" />
        </div>
      )
    },
    map
  )
}