import React from 'react'
import './index.css'
import { dropLatestPath } from '../../../utils'
import { FaArrowLeft } from "react-icons/fa"
import { IconContext } from "react-icons"

export default ({ activePath, setActivePath }) => {
  return (
    <div
      className="top-left-3 go-back hover-opacity"
      tabIndex="0"
      onKeyUp={e => {
        if (e.keyCode === 13) {
          setActivePath(
            dropLatestPath(activePath)
          )
        }
      }}
      onClick={() => {
        setActivePath(
          dropLatestPath(activePath)
        )
      }}>
      <IconContext.Provider value={{ className: "go-back-icon" }}>
        <div>
          <FaArrowLeft />
        </div>
      </IconContext.Provider>
    </div>
  )
}