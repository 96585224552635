const formatPosition = resolve => position => resolve({
  latitude: position.coords.latitude,
  longitude: position.coords.longitude,
})

const onError = (resolve, disableAlerts) => () => {
  if (!disableAlerts) {
    alert("Couldn't get your position. Using a default location.")
    resolve({
      longitude: 25,
      latitude: 60
    })
    return
  }
  resolve({
    longitude: 0,
    latitude: 0
  })
}

export const getCurrentCoordinates = (disableAlerts) => new Promise((resolve, reject) => {
  if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        formatPosition(resolve),
        onError(resolve, disableAlerts),
        { timeout: 3000 }
      )
    } else {  
      onError(resolve, disableAlerts)()
    }
  }
)