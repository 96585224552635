import React from 'react'
import { replace, last, take, curry, compose, join, dropLast, split, map, reduce, minBy, fromPairs, adjust, toPairs, apply } from 'ramda'

export const element = curry(React.createElement)
export const dropLatestPath = compose(
  join('/'),
  dropLast(1),
  split('/')
)
export const getFirstPath = compose(
  join('/'),
  take(2),
  split('/')
)
const mapDistances = currentLocation => map(
  host => ({
    host: host,
    distance: get2dDistance(currentLocation, host)
  })
)
export const getClosestHost = (currentLocation, hosts) => compose(
  _ => {
    if (_.distance > 0.4) {
      console.log('No 0.4 distance of ', _, 'Returning Suomi.')
      return 'Suomi'
    }
    return _.host
  },
  (host) => { console.log('Min: ', host); return host },
  reduce(
    minBy(_ => _.distance),
    { distance: Infinity }
  ),
  (host) => { console.log('Hosts: ', host); return host },
  mapDistances(currentLocation)
)(hosts)

export const get2dDistance = (a, b) => {
  var x_d = a.longitude - b.longitude
  var y_d = a.latitude - b.latitude

  return Math.sqrt( (x_d * x_d) + (y_d * y_d) )
}
export const mapKeys = curry((fn, obj) =>
  fromPairs(map(adjust(0, fn), toPairs(obj))));
export const replaceCITY = (path, requester) => {
  console.log(path, requester)
  return replace(
    /CITY/g,
    last(split('/', requester)),
  )(path)
}
export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};