import React from 'react'
import './index.css'
import { getFirstPath } from '../../../utils'
import { FaHome } from "react-icons/fa"
import { IconContext } from "react-icons"

export default ({ activePath, setActivePath }) => (
  <div
    className="top-left-2 home-btn hover-opacity"
    tabIndex="0"
    onKeyUp={e => {
      if (e.keyCode === 13) {
        setActivePath(
          getFirstPath(activePath)
        )
      }
    }}
    onClick={() => setActivePath(
      getFirstPath(activePath)
    )}>
    <IconContext.Provider value={{ className: "home-icon" }}>
      <div>
        <FaHome />
      </div>
    </IconContext.Provider>
  </div>
)