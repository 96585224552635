import React from 'react'
import './index.css'
import { FaGlobeAfrica } from "react-icons/fa"
import { IconContext } from "react-icons"

const getLanguageData = (structure, selectedHost) => {
  const languages = structure[ '/' + selectedHost.username ]['Suomi'].buttons
  const filteredLanguages = languages.filter( _ => _.title )
  console.log('ada', filteredLanguages)
  return filteredLanguages
}

const getFlag = (structure, selectedHost, language) => {
  const languages = structure[ '/' + selectedHost.username ]['Suomi'].buttons
  const targetLang = languages.filter(lang => lang.title === language)[0]
  return targetLang && targetLang.img
}

export default ({ showLanguages, setShowLanguages, selectedHost, structure, language }) => {
  return (
    <div
      className="top-left-2 languages-btn hover-opacity"
      tabIndex="0"
      onKeyUp={e => {
        if (e.keyCode === 13) {
          setShowLanguages(!showLanguages)
        }
      }}
      onClick={() => setShowLanguages(!showLanguages)}>
      {/* <IconContext.Provider value={{ className: "languages-btn-icon" }}>
        <div>
          <FaGlobeAfrica />
        </div>
      </IconContext.Provider> */}
      <div
        className="languages-btn-container"
        style={{
          //borderColor: language === lang.title ? 'rgba(187,70,54,0.6)' : 'rgb(230,230,230)',
          borderBottomWidth: 2,
          borderRightWidth: 2,
        }}>
        <img
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'stretch'
          }}
          alt={'flag-' + language}
          src={ getFlag(structure, selectedHost, language) } />
      </div>
    </div>
  )
}