import { split, prop, compose, join, append, dropLast, drop } from "ramda"

export const urlCity = (path) => compose(
  prop(1),
  split('/')
)(path)

export const changeLang = (language) => {
  const newHref = compose(
    join('='),
    append(language),
    dropLast(1),
    split('=')
  )(window.location.href)
  console.log(newHref)
  // window.location.href = newHref
  // window.location.replace(newHref)
  window.history.replaceState(null,null,newHref)
}