import React from 'react'
import Sound from '../Sound'
import { startsWith } from 'ramda'
import { FiExternalLink } from "react-icons/fi"
import { IconContext } from "react-icons"

export default ({ button, onClick }) => (
  button.hidden ? (
    <div className="hidden" style={{ display: 'none' }} />
  ) :
  <div className="btn sound-btn">
    { button.img &&
    <div className="sound-btn-img-container">
      <img src={button.img} className="sound-btn-img" alt={button.title} />
    </div> }
    <div style={{
      backgroundImage: `linear-gradient(
        rgba(0,0,0,0.05),
        rgba(0,0,0,0.25))
      `,
      position: 'absolute',
      width: '100%',
      height: '100%'
    }} />
    
    {/* <div
      className="sound-btn-pressable-area hover-opacity"
      onClick={onClick}
      onKeyUp={e => {
        if (e.keyCode === 13) {
          onClick(e)
        }
      }}
      tabIndex="0">
      <div className="sound-btn-title">
        { button.title }
      </div>
    </div> */}
    <div
      className="sound-btn-title hover-opacity"
      style={{
        textAlign: window.location.href && (window.location.href.split('lang=')[1] === '%D8%A7%D9%84%D8%B9%D8%B1%D8%A8%D9%8A%D9%91%D8%A9' || window.location.href.split('lang=')[1] === '%D8%AF%D8%B1%DB%8C' || window.location.href.split('lang=')[1] === 'دری') ? 'right' : 'left'
      }}
      onClick={onClick}
      onKeyUp={e => {
        if (e.keyCode === 13) {
          onClick(e)
        }
      }}
      tabIndex="0">
      { button.title }
    </div>
    { !startsWith('/', button.linkTo) && !!button.linkTo &&
    <div className="link-notification">
      <IconContext.Provider value={{ className: "link-icon" }}>
        <div>
          <FiExternalLink />
        </div>
      </IconContext.Provider>
    </div> }
    <div className="sound-container">
      { button.sound &&
      <Sound
        src={button.sound}
        containerStyle={{
          backgroundColor: 'rgba(255,155,255,0)',
        }} /> }
    </div>
  </div>
)