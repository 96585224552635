import React, { useState } from 'react'
import './index.css'
import { path, assoc, compose, split, length, endsWith } from 'ramda'
import Root from '../pages/Root'
import Menu from '../pages/Menu'
import Details from '../pages/Details'
import { element, copyToClipboard } from '../../utils'
import GoBack from '../_generics/GoBack'
import HomeBtn from '../_generics/HomeBtn'
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa"
import { IoMdMail } from "react-icons/io"
import { IconContext } from "react-icons"
import Header from '../Header'
import { changeLang } from '../../services/url';
import Sound from '../_generics/Sound';
import Search from '../_generics/Search';
import { getTranslation } from '../../services/translations';

const handleShare = () => {
  if (navigator.share) {
    navigator.share({
      title: 'Tienoo.fi | Kotoutumisen tueksi',
      text: 'Löydä videot, kartat ja ohjeet suomalaisesta yhteiskunnasta ja lähialueen palveluista. Kielet: Suomi, العربية  (arabia), English, دری  (dari), Kiswahili, Soomaali, français, español.',
      url: 'http://tienoo.fi/',
    })
    return
  }
  copyToClipboard('http://tienoo.fi/')
  alert(`http://tienoo.fi/

- Copied to clipboard!`)
}

const getLanguageData = (structure, selectedHost) => {
  const languages = structure[ '/' + selectedHost.username ]['Suomi'].buttons
  const filteredLanguages = languages.filter( _ => _.title )
  return filteredLanguages
}

const Flag = ({ lang, selectedHost, language, setSelectedHost, setActivePath, setLanguage, setShowLanguages }) => (
  <div className="Flag-container">
    <div
      className="Flag-container"
      tabIndex="0"
      onClick={() =>  {
        setLanguage(lang.title)
        changeLang(lang.title)
        setShowLanguages(false)
      }}
      onKeyUp={e => {
        if (e.keyCode === 13) {
          setLanguage(lang.title)
          changeLang(lang.title)
          setShowLanguages(false)
        }
      }}>
      <div
        className="Flag"
        style={{
          //borderColor: language === lang.title ? 'rgba(187,70,54,0.6)' : 'rgb(230,230,230)',
          borderBottomWidth: 2,
          borderRightWidth: 2,
          opacity: language === lang.title ? 0.5 : 1
        }}
        >
        <img
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'stretch'
          }}
          alt={'flag-' + lang.title}
          src={ lang.img } />
      </div>
      <div className="flag-title-container">{ lang.title }</div>
    </div>
    <div className="flag-sound-btn-container hover-opacity">
      <Sound
        src={lang && lang.sound}
        tabIndex="0"
        containerStyle={{
          color: 'rgba(0,0,0,0.6)'
        }} />
    </div>
  </div>
)

const Empty = ({ activePath, setActivePath }) => (
  <div style={{ padding: 20 }}>
    { ' ' + activePath }
    <div style={{ paddingTop: 20 }}>
      Ei sisältöjä.
    </div>
    { length(split('/', activePath)) > 1 && 
    <GoBack
      activePath={activePath}
      setActivePath={setActivePath} /> }
    <HomeBtn
      activePath={activePath}
      setActivePath={setActivePath} />
  </div>
)

const mapToComponent = (type) => {
  if (type === 'root') return Root
  if (type === 'menu') return Menu
  if (type === 'details') return Details
  return Empty
}

const makeContent = (
  activePath,
  setActivePath,
  selectedHost,
  setSelectedHost,
  structure,
  hosts,
  language,
  coordinatesStored,
  showLanguages,
  setShowLanguages,
  showSearch,
  setShowSearch
) => element(
  mapToComponent(
    path([ activePath, language, 'component', 0, 'type'], structure)
  ),
  compose(
    assoc('selectedHost', selectedHost),
    assoc('showSearch', showSearch),
    assoc('setShowSearch', setShowSearch),
    assoc('setSelectedHost', setSelectedHost),
    assoc('coordinatesStored', coordinatesStored),
    assoc('structure', structure),
    assoc('hosts', hosts),
    assoc('activePath', activePath),
    assoc('setActivePath', setActivePath),
    assoc('showLanguages', showLanguages),
    assoc('language', language),
    assoc('setShowLanguages', setShowLanguages),
    path([ activePath, language ])
  )(structure),
  null
)

export default ({
  activePath,
  setActivePath,
  selectedHost,
  setSelectedHost,
  structure,
  hosts,
  language,
  setLanguage,
  coordinatesStored
}) => {
  const [ showLanguages, setShowLanguages ] = useState(false)
  const [ showSearch, setShowSearch ] = useState(false) // false when in production
  const isTanzaniaVersion = (selectedHost.username === 'Dar-es-Salaam' || selectedHost.username === 'Dodoma')
  return (
    <div className="Page background-macro">
      <Header
        structure={structure}
        selectedHost={selectedHost}
        setSelectedHost={setSelectedHost}
        language={language}
        activePath={activePath}
        setActivePath={setActivePath}
        setLanguage={setLanguage}
        showLanguages={showLanguages}
        showSearch={showSearch} 
        setShowSearch={setShowSearch} />        
        <div className="Page-content">        
          <div className="width-restricted">
          { showSearch && 
          <div>
            <Search
              selectedHost={selectedHost}
              structure={structure}
              language={language}
              setActivePath={setActivePath}
              setShowSearch={setShowSearch}
            />
          </div> }
            
            { showLanguages &&
            <div className="lang-modal-bg">
              <div className="lang-modal-content">
                <div className="lang-modal-select-lang-title">{ getTranslation(language, 'Valitse kieli') }</div>
                <div className="flags-container">
                  { getLanguageData(structure, selectedHost).filter(lang => {
                    if (window.location.hostname.includes('co.tz')) {
                      return lang.title === 'English' || lang.title === 'Kiswahili'
                    }
                    return true
                  }).map(lang => (
                    <Flag
                      lang={lang}
                      selectedHost={selectedHost}
                      setShowLanguages={setShowLanguages}
                      language={language}
                      setLanguage={setLanguage}
                      setSelectedHost={setSelectedHost}
                      setActivePath={setActivePath} />
                  )) }
                </div>
              </div>              
            </div> }


            { makeContent(
                activePath,
                setActivePath,
                selectedHost,
                setSelectedHost,
                structure,
                hosts,
                language,
                coordinatesStored,
                showLanguages,
                setShowLanguages,
                showSearch,
                setShowSearch
            )}
            {/* <div style={{ color: 'rgba(150,200,120,1)' }}>{ activePath }</div> */}
            {/* <div style={{ height: 150 }} /> */}
            
            { endsWith('/Kategoriat', activePath) &&
            <div style={{ padding: 30, paddingBottom: 60, position: 'relative', bottom: 0, right: 0 }}>
              {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <div className="Footer-link">Evästeet</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <div className="Footer-link">Tietosuojaseloste</div>
              </div> */}
              <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
                { isTanzaniaVersion &&
                <div style={{ width: 130, maxWidth: '35vw', textAlign: 'left', paddingRight: 10 }}>
                  <img src={require('./jidi-logo.png')} style={{ width: '100%', objectFit: 'contain' }} alt={'tienoo-logo'}/>
                </div> }
                <div style={{ width: 200, maxWidth: '45vw', textAlign: 'left' }}>
                  <img src={isTanzaniaVersion ?
                    require('./YL_Logo_EN.png') :
                    require('./YL_Logo_FI.png')
                  } style={{ width: '100%', objectFit: 'contain' }} alt={'tienoo-logo'}/>
                </div>
                <IconContext.Provider value={{ className: "email-icon" }}>
                  <div className="hover-opacity" onClick={() => window.open('mailto:tienoo@yhteisetlapsemme.fi')}>
                    <IoMdMail />
                  </div>
                </IconContext.Provider>
                { !isTanzaniaVersion &&
                <IconContext.Provider value={{ className: "facebook-icon" }}>
                  <div className="hover-opacity" onClick={() => { window.location.href = 'http://www.facebook.com/tienoofi' }}>
                    <FaFacebook />
                  </div>
                </IconContext.Provider> }
                {/* <IconContext.Provider value={{ className: "instagram-icon" }}>
                  <div className="hover-opacity" onClick={handleShare}>
                    <FaInstagram />
                  </div>
                </IconContext.Provider> */}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                {<div className="Footer-link"></div>}
                <a href={isTanzaniaVersion ?
                  "https://docs.google.com/document/d/e/2PACX-1vRlHW866_WhsRW0fqkLjvybanErXJrbVKhOeIG8JYRf9uPtBVcl0vtM5_rDpic9WShZ0TbTlxWAuNb5/pub" :
                  "https://docs.google.com/document/u/1/d/e/2PACX-1vSY7Yxh_YuT2z_NfUXJ8PVd84qfEInz6G3wTLpEk1TMw_dDkP-0H7L8dgNS8bYk-4F8MuxsYRSyeZ7A/pub"} className="Footer-link">{ isTanzaniaVersion ? 'Accessibility Statement' : 'Saavuttettavuusseloste' }</a>
                <a href={isTanzaniaVersion ?
                  "https://docs.google.com/document/d/e/2PACX-1vTJ8hPeMkK_VN8z_4CswczV_5F5X8O4FL1tcwV0l0wy4pkzTxho3NZ-VVKtSPAfyHwaZ8Ba2kvs82r3/pub" :
                  "https://docs.google.com/document/u/2/d/e/2PACX-1vQkR2y67ABs8cIV8AAb_qZQRpsu7VYOwCWLWvAlTgn7M290WBcgB39t_PpXU74WmUBCr0zg8YYxmfGQ/pub"} className="Footer-link">{ isTanzaniaVersion ? 'Privacy Policy' : 'Tietosuojaseloste' }</a>
              </div>
            </div> }
          </div>
        </div>
      </div>
  )
}
