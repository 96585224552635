import React from 'react'
import './index.css'
import { map as rmap, split, startsWith } from 'ramda'
import Video from '../../_generics/Video'
import Sound from '../../_generics/Sound'
import ButtonWithSound from '../../_generics/ButtonWithSound'
import Ticker from 'react-ticker'
import GoBack from '../../_generics/GoBack'
import HomeBtn from '../../_generics/HomeBtn'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import GoogleMap from '../../_generics/GoogleMap'
import { FaMapMarkerAlt } from "react-icons/fa"
import { IconContext } from "react-icons"
import Picture from '../../_generics/Picture';

const makeButtons = (activePath, setActivePath) => rmap(
  button => (
    <ButtonWithSound
      key={button.title}
      button={button}
      onClick={() => {
        if (!button.linkTo) {
          return
        }
        if (startsWith('/', button.linkTo)) {
          setActivePath(/*activePath + '/' + */
            button.linkTo,
            button.title
          )
        } else {
          if (startsWith('http://', button.linkTo) || startsWith('https://', button.linkTo)) {
            //window.location.href = button.linkTo
            window.open(button.linkTo, '_blank')
          } else if (startsWith('tel:', button.linkTo)) {
            window.location.href = button.linkTo
          } else {
            //window.location.href = 'http://' + button.linkTo
            window.open('http://' + button.linkTo, '_blank')
          }
        }
      }} />
  )
)

const PageText = ({ text }) => ( text && text[0] && text[0].content ) ? (
  <div className="page-text">
    <pre className="small-text">
      { text[0].content }
    </pre>
    <div className="page-text-sound">
      <Sound src={text[0].sound}/>
    </div>
  </div> 
) : <div />

export default ({
  activePath,
  setActivePath,
  selectedHost,
  setSelectedHost,
  hosts,
  picture,
  video,
  text,
  map,
  buttons,
  infoScroll,
  coordinatesStored
}) => {
  const sidescrollTexts = infoScroll && infoScroll[0] && infoScroll[0].text && split(',', infoScroll[0].text)
  console.log('host,', selectedHost)
  return (
    <div className="Root fade-in">
      <div className="top-left city-selection">

        <div>
          <IconContext.Provider value={{ className: "gps-icon" }}>
            <div>
              <FaMapMarkerAlt />
            </div>
          </IconContext.Provider>
        </div>


        <Dropdown
          controlClassName="city-dropdown"
          options={
            rmap(
              _ => ({
                label: _.username,
                value: _
              }),
              hosts
            )
          }
          onChange={(_) => setSelectedHost(_.value)}
          value={selectedHost.username} />
          <div style={{ marginBottom: -13, marginRight: -5, color: 'rgba(0,0,0,0.6)' }}>
            <Sound src={selectedHost.sound} />
          </div>
      </div>

      <PageText text={text} />

      <Picture picture={picture} />

      <Video video={video} />


      { infoScroll && infoScroll[0] && infoScroll[0].text &&
      <div style={{ paddingTop: 15, paddingBottom: 30, position: 'relative', marginRight: 40 }}>
        <Sound src={infoScroll[0].sound} containerStyle={{ position: 'absolute', top: 10, right: -40, zIndex: 100000, color: 'rgba(0,0,0,0.6)' }} />
        <Ticker>
          {({ index }) => 
            <div className="ticker-item-1 small-text">
              { sidescrollTexts[index % sidescrollTexts.length] }
            </div>
          }
        </Ticker>
      </div> }

      <div className="btns-container">
        { makeButtons(activePath, setActivePath)(buttons) }
      </div>

      <GoogleMap map={map} coordinatesStored={coordinatesStored} />
      
    </div>
  )
}
