import React from 'react'
import './index.css'

var Loader = require('react-loader');

export default ({  }) => (
  <div className="Loading background-macro">
    <img className="Loading-icon" src={require('./tienoo_logo_red.png')} />
    <div className="small-text Loading-text" >Getting your location...</div>
{/*     <Loader
        loaded={false}
        lines={13}
        length={20}
        width={10}
        radius={30}
        corners={1}
        rotate={0}
        direction={1}
        color="rgba(0,0,0,0.3)"
        speed={0.8}
        trail={60}
        shadow={false}
        hwaccel={true}
        className="spinner"
        zIndex={2e9}
        top="50%"
        left="50%"
        scale={0.7}
        loadedClassName="loadedContent"
      /> */}
  </div>
)