import React from 'react'
import './index.css'
import { includes, map, split, startsWith, compose } from 'ramda'
import Sound from '../Sound'

export default ({ picture }) => {
  if (!picture) {
    return <div />
  }
  return map(
    item => item && item.source && !item.hidden ? (
      <div key={item.source} className="pic-container">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
          <div className="small-text">{ item.title }</div>
          <Sound src={item.sound} />
        </div>
        <img className={'picture'} src={item.source}></img>
      </div>
    ) : (
      <div key={'nopic'} />
    ),
    picture
  )
}
