import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import {
  HashRouter as Router,
} from 'react-router-dom'

/* if ("serviceWorker" in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js')
      .then(_ => console.log('Success registering the serviceWorker.'))
      .catch(_ => console.log('Failure registering the serviceWorker.'))
  })
} 
 */
ReactDOM.render(
  <Router><App /></Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
