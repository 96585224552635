import React from 'react'
import './index.css'
import { includes, map, split, startsWith, compose } from 'ramda'
import Sound from '../Sound'

const getEmbedCode = () => {
  
}

export default ({ video }) => {
  if (!video) {
    return <div />
  }
  return map(
    item => item && item.source && !item.hidden ? (
      <div key={item.source} className="vid-container">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
          <div
            className="small-text"
            style={{
              textAlign: window.location.href && (window.location.href.split('lang=')[1] === '%D8%A7%D9%84%D8%B9%D8%B1%D8%A8%D9%8A%D9%91%D8%A9' || window.location.href.split('lang=')[1] === '%D8%AF%D8%B1%DB%8C' || window.location.href.split('lang=')[1] === 'دری') ? 'right' : 'left'
            }}>
            { item.title }
          </div>
          <Sound src={item.sound} />
        </div>
        { includes('youtube', item.source) || includes('vimeo', item.source) ? (
          includes('youtube', item.source) ?
         <iframe
          className="vid"
          src={ startsWith('https://www.youtube', item.source) ? item.source : split('"', item.source)[5]}
          frameBorder='0'
          allow='autoplay; encrypted-media'
          allowFullScreen
          title='video'
        /> : 
          <iframe
          className="vid"
          src={ compose(
            _ => split('"', _)[1],
            _ => split('src', _)[1]
          )(item.source) }
          frameBorder='0'
          allow='autoplay; encrypted-media'
          allowFullScreen
          title='video'
        /> ) : 
        <video className="vid" preload="metadata" controls>
          <source src={item.source + '#t=0.1'} type="video/mp4"/>
          Your browser does not support the video tag.
        </video> }
      </div>
    ) : (
      <div key={'novid'} />
    ),
    video
  )
}

/*

if (!item.embed) return <div key={'none'} />
      if (!startsWith('<iframe src="https://www.google.com/maps', item.embed)) return <div key={'nourl'} />
      const googleMapslink = split('"', item.embed)[1]    

*/

/*

<iframe src="https://player.vimeo.com/video/259411563" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
<p><a href="https://vimeo.com/259411563">A Love Letter to Winter</a> from <a href="https://vimeo.com/thenorthface">The North Face</a> on <a href="https://vimeo.com">Vimeo</a>.</p>

*/