import React, { useState } from 'react'
import './index.css'
import { map, split } from 'ramda'
import GoBack from '../../_generics/GoBack'
import Video from '../../_generics/Video'
import Sound from '../../_generics/Sound'
import ButtonWithSound from '../../_generics/ButtonWithSound'
import HomeBtn from '../../_generics/HomeBtn'
import Ticker from 'react-ticker'

const makeLinks = map(
  link => (
    <div key={link.title} className="link-container">
      <img style={{ width: '20%', height: '100%' }} src={'https://image.cnbcfm.com/api/v1/image/106278717-1575487551656screenshot2019-12-04at2.25.16pm.png?v=1575487585&w=678&h=381'} />
      <a className="link-title hover-opacity" href={link.address}>
        {link.title}
      </a>
      <div className="link-sound-container">
        <Sound src={link.sound} />
      </div>
    </div>
  )
)

const AdditionalDetails = ({ button }) => (
  <div>
    { makeLinks(button.links) }
    { button.map &&
    <img style={{ width: '100%' }} src={'https://image.cnbcfm.com/api/v1/image/106278717-1575487551656screenshot2019-12-04at2.25.16pm.png?v=1575487585&w=678&h=381'} /> }
  </div>
)

const DetailView = ({ button, setActivePath, activePath }) => {
  const [ opened, setOpened ] = useState(false)

  return (
    <div>
      <ButtonWithSound
        button={button}
        onClick={() => setOpened(!opened)} />
      { opened && 
      <AdditionalDetails button={button} /> }
    </div>
  )
}

const makeButtons = (activePath, setActivePath) => map(
  button => (
    <DetailView 
      key={button.title}
      button={button}
      setActivePath={setActivePath}
      activePath={activePath} />
  )
)

export default ({ activePath, setActivePath, buttons, video, links, text, map, infoScroll, coordinatesStored }) => {
  const sidescrollTexts = infoScroll && infoScroll[0] && infoScroll[0].text && split(',', infoScroll[0].text)
  return (
    <div className="Menu">
      <div className="fade-in">
        { infoScroll && infoScroll[0] && infoScroll[0].text &&
        <div style={{ paddingTop: 0, paddingBottom: 30 }}>
          <Ticker>
            {({ index }) => 
              <div className="ticker-item-1">
                { sidescrollTexts[index % sidescrollTexts.length] }
              </div>
            }
          </Ticker> 
        </div> }
        { text && text[0] && text[0].content &&
        <pre style={{ padding: 20 }}>
          { text[0].content }
        </pre> }
        { links && makeLinks(links) }
        <Video video={video} />
        { map &&
        <img style={{ width: '100%' }} src={'https://image.cnbcfm.com/api/v1/image/106278717-1575487551656screenshot2019-12-04at2.25.16pm.png?v=1575487585&w=678&h=381'} alt={'map'} /> }
        <div className="btns-container">
          { buttons && makeButtons(activePath, setActivePath)(buttons) }
        </div>
      </div>
      <GoBack
        activePath={activePath}
        setActivePath={setActivePath} />
      <HomeBtn
        activePath={activePath}
        setActivePath={setActivePath} />
    </div>
  )
}
