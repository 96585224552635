import React, { useState } from 'react'
import './index.css'
import { startsWith } from 'ramda'
import ButtonWithSound from '../ButtonWithSound';
import { AiOutlineSearch } from "react-icons/ai"
import { MdClose } from "react-icons/md"
import { IconContext } from "react-icons"
import { getTranslation } from '../../../services/translations';

const MAX_TITLE_LENGTH = 70

const minimizeStructure = (pages, language, host, searchTerm) => {
  return Object.keys(pages).map(pageName => {
    const pageContent = pages[pageName][language]
    const minimumContent = pageContent.buttons.filter(_ => !_.hidden).map(button => ({
      sortPriority: 0,
      ...button
    }))
    const pageVideos = pageContent.video.filter(_ => !_.hidden).map(vid => ({
      sortPriority: 1,
      title: vid.title,
      sound: vid.sound,
      img: 'https://files.tienoo.fi/haku-video-tienoo.jpg',
      linkTo: pageName
    }))
    const pageMaps = pageContent.map.filter(_ => !_.hidden).map(mp => ({
      sortPriority: 2,
      title: mp.title,
      sound: mp.sound,
      img: 'https://files.tienoo.fi/haku-kartta-tienoo.jpg',
      linkTo: pageName
    }))
    const pageImages = pageContent.picture.filter(_ => !_.hidden).map(pic => ({
      sortPriority: 3,
      title: pic.title,
      sound: pic.sound,
      img: 'https://files.tienoo.fi/tienoo-haku-kuva.jpg',
      linkTo: pageName
    }))
    const pageTexts = pageContent.text.filter(_ => !_.hidden).map(txt => ({
      sortPriority: 4,
      title: txt.content,
      sound: txt.sound,
      img: 'https://files.tienoo.fi/haku-teksti-tienoo.jpg',
      linkTo: pageName
    }))

    return [ ...minimumContent, ...pageVideos, ...pageMaps, ...pageImages, ...pageTexts ]
  }).reduce((acc, val) => [ ...acc, ...val ], [])
  
}

export default ({ selectedHost, structure, language, setActivePath, setShowSearch  }) => { 
  const [ searchTerm, setSearchTerm ] = useState('')
  const searchResults = minimizeStructure(structure, language, selectedHost, searchTerm)
    .filter(_ => 
      !(_.title === 'Suomi') &&
      !(_.title === 'العربيّة') &&
      !(_.title === 'English') &&
      !(_.title === 'دری') &&
      !(_.title === 'Kiswahili') &&
      !(_.title === 'Français') &&
      !(_.title === 'Español')
    )
    .filter(_ => {      
      if (searchTerm.length < 2) return false
      return _.title.toLowerCase().includes(searchTerm.toLowerCase())
    })
    .sort((a, b) => a.sortPriority - b.sortPriority)
    .slice(0, 21)
    .map(_ => ({ ..._, title: _.title.substring(0, MAX_TITLE_LENGTH) + (_.title.length > MAX_TITLE_LENGTH ? '...' : '') }))

  return (
    <div className="Search">      
      <div className="search-modal-content">        
        <div className="search-title">
          <IconContext.Provider value={{ className: "search-title-icon" }}>
            <div>
              <AiOutlineSearch />
            </div>
          </IconContext.Provider>
        </div>
        <div className="search-input-container">
          <input 
            className="search-input"
            value={searchTerm}
            placeholder={getTranslation(language, 'Hae tästä')}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="search-results-container">
        { searchResults.map((button, index) => (
          <ButtonWithSound
            key={button.title + '-' + button.linkTo + '-' + index}
            button={button}
            onClick={() => {
              if (!button.linkTo) {
                return
              }
              if (startsWith('/', button.linkTo)) {
                setActivePath(/*activePath + '/' + */
                  button.linkTo
                )
                setShowSearch(false)
              } else {
                if (startsWith('http://', button.linkTo) || startsWith('https://', button.linkTo)) {
                  // window.location.href = button.linkTo
                  window.open(button.linkTo, '_blank')
                } else if (startsWith('tel:', button.linkTo)) {
                  window.location.href = button.linkTo
                } else {
                  //window.location.href = 'http://' + button.linkTo
                  window.open('http://' + button.linkTo, '_blank')
                  setShowSearch(false)
                }
              }
            }} />
        )) }
        </div>
        <div onClick={() => setShowSearch(false)} className="close-btn-container">
          <IconContext.Provider value={{ className: "close-btn" }}>
            <div>
              <MdClose />
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </div>
  )
}