import React, { useEffect, useState } from 'react'
import './index.css'
import Page from '../Page'
import { getMockStructure, getMockHosts } from '../../mocks'
import { stopPlaying } from '../../services/audio.js'
import Footer from '../Footer'
import { getClosestHost, replaceCITY } from '../../utils';
import { getCurrentCoordinates } from '../../services/location';
import Loading from '../_generics/Loading';
import { getHosts, getSite, getSite_v2 } from '../../services/data';
import { split, keys, map as rmap, length } from 'ramda'
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
  useLocation
} from "react-router-dom";
import { urlCity } from '../../services/url'
import GoBack from '../_generics/GoBack'
import HomeBtn from '../_generics/HomeBtn'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-154894887-1');

const AudioBus = (props) => (
  <audio id="player">
    <source src={'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3'} type="audio/mpeg" />
    Your browser does not support the audio element.
  </audio>
)

const makeLangQuery = lang => '?lang=' + lang

export default (props) => {
  const [ structure, setStucture ] = useState({})
  const [ hosts, setHosts ] = useState([])
  const [ selectedHost, setSelectedHost ] = useState(null)
  const [ fetching, setFetching ] = useState(true)
  const [ language, setLanguage ] = useState(
    window.location.hostname.includes('co.tz') ?
    'English' :
    'Suomi'
  )
  const [ coordinatesStored, setCoordinates ] = useState({})

  let browserHistory = useHistory()
  const url_data = useLocation()
  const urlPath = url_data.pathname
  const urlParams = new URLSearchParams(url_data.search)

  useEffect(() => {
    const city = urlCity(urlPath)
    const queryLang = urlParams.get('lang')
    if (queryLang) {
      setLanguage(queryLang)
    }

    getHosts().then(
      data => {
        setHosts(data)

        if (city) {
          setSelectedHost({ username: city })
          getSite_v2(city).then(
            data => {
              setStucture(data)
              setFetching(false)
            }
          )
        } else {
          getCurrentCoordinates().then(
            coordinates => {
              setCoordinates(coordinates)
              let closestHost = getClosestHost(coordinates, data)
              if (window.location.hostname.includes('co.tz')) {
                closestHost = { username: 'Dar-es-Salaam' }
              }
              setSelectedHost(closestHost)
              browserHistory.replace('/' + closestHost.username + '/Kategoriat' + makeLangQuery(language))
              getSite_v2(closestHost.username).then(
                data => {
                  setStucture(data)
                  setFetching(false)
                }
              )
            }
          )
        }
      }
    )
  }, [])

  return (
    <div className="App background-macro">
      { fetching ?
      <Loading /> :
      <Switch>
        { rmap(route => (
          <Route exact key={route} path={route}>
            <Page
              activePath={route}
              coordinatesStored={coordinatesStored}
              setActivePath={(path, newLanguage = null) => {
                stopPlaying(document.getElementById('player'))
                if (newLanguage) {
                  setLanguage(newLanguage)
                  let newPage = replaceCITY(path, selectedHost.username) + makeLangQuery(newLanguage)
                  browserHistory.push(newPage)
                  ReactGA.pageview(newPage);
                } else {
                  let newPage = replaceCITY(path, selectedHost.username) + makeLangQuery(language)
                  browserHistory.push(newPage)
                  ReactGA.pageview(newPage);
                }
              }}
              hosts={hosts}
              selectedHost={selectedHost}
              setSelectedHost={
                (host) => {
                  setFetching(true)
                  setSelectedHost(host)
                  browserHistory.push('/' + host.username + '/Kategoriat' + makeLangQuery(language))
                  getSite_v2(host.username).then(
                    data => {
                      setStucture(data)
                      setFetching(false)
                    }
                  )
                }
              }
              language={language}
              setLanguage={setLanguage}
              structure={structure} />
          </Route> ),
          keys(structure)
        )}
        <Route path={urlPath}>
          <Page
            activePath={urlPath}
            coordinatesStored={coordinatesStored}
            setActivePath={(path, newLanguage = null) => {
              stopPlaying(document.getElementById('player'))
              if (newLanguage) {
                setLanguage(newLanguage)
                let newPage = replaceCITY(path, selectedHost.username) + makeLangQuery(newLanguage)
                browserHistory.push(newPage)
                ReactGA.pageview(newPage);
              } else {
                let newPage = replaceCITY(path, selectedHost.username) + makeLangQuery(language)
                browserHistory.push(newPage)
                ReactGA.pageview(newPage);
              }
            }}
            hosts={hosts}
            selectedHost={selectedHost}
            setSelectedHost={
              (host) => {
                setFetching(true)
                setSelectedHost(host)
                browserHistory.push('/' + host.username + '/Kategoriat' + makeLangQuery(language))
                getSite_v2(host.username).then(
                  data => {
                    setStucture(data)
                    setFetching(false)
                  }
                )
              }
            }
            language={language}
            setLanguage={setLanguage}
            structure={structure} />
        </Route>
      </Switch> }
      <AudioBus />
      {/* <Footer /> */}
    </div>
  )
}
