import React, { useState } from 'react'
import './index.css'
import { startPlaying, stopPlaying } from '../../../services/audio.js'
import { FaBell } from "react-icons/fa"
import { IconContext } from "react-icons"
import { GiSpeaker } from "react-icons/gi";

export default ({ src, containerStyle = {} }) => {
  const [ playing, setPlaying ] = useState(false)

  return src ? (
    <div
      className="audio-icon-container hover-opacity"
      style={{ ...containerStyle }}
      onClick={() => {
        const player = document.getElementById('player')
        if (!playing || decodeURI(player.src) !== decodeURI(src)) {
          startPlaying(player, src)
          setPlaying(true)
        } else {
          stopPlaying(player)
          setPlaying(false)
        } 
      }}
      onKeyUp={e => {
        if (e.keyCode === 13) {
          const player = document.getElementById('player')
          if (!playing || decodeURI(player.src) !== decodeURI(src)) {
            startPlaying(player, src)
            setPlaying(true)
          } else {
            stopPlaying(player)
            setPlaying(false)
          } 
        }
      }}
      tabIndex="0">
      <IconContext.Provider value={{ className: "audio-icon" }}>
        <div>
          <GiSpeaker />
        </div>
      </IconContext.Provider>
    </div> 
  ) : (
    <div />
  )
}