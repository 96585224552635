import React, { useLocation } from 'react'
import { changeLang } from '../../services/url';
import { getFirstPath } from '../../utils';

const getLanguageData = (structure, selectedHost) => {
  const languages = structure[ '/' + selectedHost.username ]['Suomi'].buttons
  const filteredLanguages = languages.filter( _ => _.title )
  console.log('ada', filteredLanguages)
  return filteredLanguages
}

const Flag = ({ lang, selectedHost, language, setSelectedHost, setActivePath, setLanguage }) => (
  <div
    className="Flag"
    tabIndex="0"
    style={{
      //borderColor: language === lang.title ? 'rgba(187,70,54,0.6)' : 'rgb(230,230,230)',
      borderBottomWidth: 2,
      borderRightWidth: 2,
      opacity: language === lang.title ? 0.5 : 1
    }}
    onClick={() =>  {
      setLanguage(lang.title)
      changeLang(lang.title)
    }}
    onKeyUp={e => {
      if (e.keyCode === 13) {
        setLanguage(lang.title)
        changeLang(lang.title)
      }
    }}>
    <img
      style={{
        height: '100%',
        width: '100%',
        objectFit: 'stretch'
      }}
      alt={'flag-' + lang.title}
      src={ lang.img } />
  </div>
)

export default ({ structure, selectedHost, language, setSelectedHost, setActivePath, setLanguage, activePath, showLanguages, showSearch, setShowSearch }) => {

  return (
    <div className="header-container">
      <div className="header">
        <div className="header-img-container">
          <img
            src={require('./tienoo_logo_red.png')}
            className="header-img hover-opacity" alt="Tienoo_logo"
            onClick={() => setActivePath(
              getFirstPath(activePath) + '/Kategoriat'
            )} />
        </div>
        { /* !('/' + selectedHost.username === activePath) && showLanguages &&
        <div className="change-language-container">
          { getLanguageData(structure, selectedHost).map(
              lang => (
                <Flag
                  lang={lang}
                  selectedHost={selectedHost}
                  language={language}
                  setLanguage={setLanguage}
                  setSelectedHost={setSelectedHost}
                  setActivePath={setActivePath} />
              )
          ) }
        </div> */}
      </div>
    </div>
  )
}